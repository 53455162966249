<template>
    <div>
        <div>

            <!-- Search setion -->
            <b-row>
                <!-- Search -->
                <b-col cols="12" md="3">
                    <b-form-group>
                        <h5>Range appointment date</h5>
                        <flat-pickr v-model="rangeDate" class="form-control" :config="{ mode: 'range' }" size="sm"
                            style="height: 30px" />
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                    <b-form-group>
                        <h5>Search</h5>
                        <b-form-input v-model="searchingText" @keyup="masterSearch($event)" size="sm" type="text" />
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="1" class="mb-1 ml-1" style="margin-top: 1.7rem !important">
                    <b-button class="btn btn-primary float-right" @click="getClientesByFilter('')" size="sm"
                        style="background: #7367f0 !important">
                        Filter
                    </b-button>
                </b-col>
            </b-row>
            <!--End Search setion -->



            <div style="overflow-x: scroll; min-height: 500px" v-if="totalItems > 0">
                <table small caption-top responsive style="font-size: small" id="table" class="table b-table">
                    <thead>
                        <tr>
                            <th>Actions</th>
                            <th aria-sort="none" @click="getItemsSort('booking_id')"><i
                                    class="fa fa-fw fa-sort"></i>Confirmation number</th>
                            <th @click="getItemsSort('garage_time')">Preauthorization reminder</th>
                            <th @click="getItemsSort('selfpay_name')">Customer</th>
                            <th @click="getItemsSort('selfpay_phone_number')">Customer Phone</th>
                            <th @click="getItemsSort('statuscodes_status')">Status</th>
                            <th @click="getItemsSort('service_type')">Service Type</th>
                            <th @click="getItemsSort('payment_type')">Booking Category</th>
                            <th @click="getItemsSort('company_legal_name')">Company</th>
                            <th @click="getItemsSort('pickup_time')">PickUp time</th>
                            <th @click="getItemsSort('appoinment_datetime')">Appointment Datetime</th>
                            <th aria-sort="none" class="headerth" @click="getItemsSort('garage_time')"> Central zone
                                time</th>
                            <th @click="getItemsSort('driver_name')">Driver</th>
                            <th @click="getItemsSort('driver_vehicle_type')">Vehicle Type</th>
                            <th @click="getItemsSort('driver_phone_number')">Driver phone</th>
                            <th @click="getItemsSort('from')" style="min-width: 100px">Pickup Address</th>
                            <th @click="getItemsSort('to')" style="min-width: 100px">Drop Off Address</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in listItems" :key="item.booking_id" @click="getTicketData(item)">
                            <!-- Start Actions  -->
                            <td>
                                <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL"
                                    transition="scale-transition" :offset-y="true">
                                    <template #button-content>
                                        <feather-icon icon="MoreVerticalIcon" size="16"
                                            class="align-middle text-body" />
                                    </template>
                                    <template v-slot:activator="{ on, attrs }">
                                        <b-btn color="primary" v-bind="attrs" v-on="on" icon ripple>
                                        </b-btn>
                                    </template>
                                    <b-list-group style="padding: 0px; margin-bottom: 0px" dense rounded>
                                        <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                                            <b-list-group-item class="font-weight-bold"
                                                style="border: none; padding: 5px; color: #7367f0"
                                                @click="openUpdateBooking(item.booking_id)">
                                                <feather-icon icon="CheckIcon" />
                                                Edit
                                            </b-list-group-item>
                                        </b-list-group-item>
                                    </b-list-group>
                                    <b-list-group style="padding: 2px; margin-bottom: 2px" dense rounded>
                                        <router-link class="urlPagina"
                                            :to="{ name: 'details-booking', query: { id: item.booking_id } }">
                                            <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                                                <b-list-group-item class="font-weight-bold"
                                                    style="border: none; padding: 5px">
                                                    <feather-icon icon="FileTextIcon" />
                                                    Details
                                                </b-list-group-item>
                                            </b-list-group-item>
                                        </router-link>
                                    </b-list-group>
                                    <!-- is_charge_hold 3 = is expirated -->
                                    <b-list-group
                                        v-if="(item.charge_id === null && (item.status == 4) == false) || item.is_charge_hold == 3"
                                        style="padding: 0px; margin-bottom: 0px" dense rounded>
                                        <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                                            <b-list-group-item class="font-weight-bold"
                                                style="border: none; padding: 5px; color: #7367f0"
                                                @click="showPaymentMethods(item)">
                                                <feather-icon icon="CheckIcon" />
                                                Pre-authorize payment
                                            </b-list-group-item>
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-dropdown>
                            </td>
                            <!-- End Actions  -->
                            <td>{{ item.booking_id }}</td>
                            <td>
                                <span :class="{ textDanger: ShouldPreauthirze(item.appoinment_datetime, item) }">{{
                                    getReminder(item.appoinment_datetime, item)
                                }}</span>
                            </td>
                            <td>{{ item.selfpay_name == null ? "" : item.selfpay_name }} {{
                                item.selfpay_lastname == null ? "" : item.selfpay_lastname }}</td>
                            <td>{{ item.selfpay_phone_number }}</td>
                            <!-- Start Status  -->
                            <td v-if="item.statuscodes_code == 0" style="background: #e89e15; color: white">
                                {{ item.statuscodes_status }}
                            </td>
                            <td v-if="item.statuscodes_code == 1" style="color: white; background: #5d1e76">
                                {{ item.statuscodes_status }}
                            </td>
                            <td v-if="item.statuscodes_code == 2" style="background: #a067a0; color: white">
                                {{ item.statuscodes_status }}
                            </td>
                            <td v-if="item.statuscodes_code == 3" style="background: #329432; color: white">
                                {{ item.statuscodes_status }}
                            </td>
                            <td v-if="item.statuscodes_code == 4" style="background: #f9cfcf; color: crimson">
                                {{ item.statuscodes_status }}
                            </td>
                            <td v-if="item.statuscodes_code == 5" style="background: #cdcdcd">
                                {{ item.statuscodes_status }}
                            </td>
                            <td v-if="item.statuscodes_code == 6" style="background: #b0b02f; color: white">
                                {{ item.statuscodes_status }}
                            </td>
                            <td v-if="item.statuscodes_code == 7" style="background: #4b4b4b; color: white">
                                {{ item.statuscodes_status }}
                            </td>
                            <td v-if="item.statuscodes_code == 8" style="background: royalblue; color: white">
                                {{ item.statuscodes_status }}
                            </td>
                            <!-- <td v-if="item.status == 8">{{ item.statuscodes_status }}</td> -->
                            <td v-if="item.statuscodes_code == 9" style="background: #6161ff; color: white">
                                {{ item.statuscodes_status }}
                            </td>
                            <td v-if="item.statuscodes_code == 10" style="background: rgb(198 239 38); color: black">
                                {{ item.statuscodes_status }}
                            </td>
                            <td v-if="item.statuscodes_code == 11" style="background: rgb(233 78 62); color: #c9c6c6">
                                {{ item.statuscodes_status }}
                            </td>
                            <td v-if="item.statuscodes_code == 12"
                                style="background: rgb(247, 91, 187); color: #fcfcfc">
                                {{ item.statuscodes_status }}
                            </td>
                            <td v-if="item.statuscodes_code == 13" style="background: rgb(93, 10, 72); color: #fcfcfc">
                                {{ item.statuscodes_status }}
                            </td>
                            <td v-if="item.statuscodes_code == 14"
                                style="background: rgb(21, 124, 117); color: #fcfcfc">
                                {{ item.statuscodes_status }}
                            </td>
                            <td v-if="item.statuscodes_code == 15" style="background: rgb(31, 24, 136); color: #fcfcfc">
                                {{ item.statuscodes_status }}
                            </td>
                            <!-- End Status  -->
                            <td>{{ getServiceType(item.service_type) }}</td>
                            <td>{{ getTypeClient(item) }}</td>
                            <td>{{ getCompanyName(item) }}</td>
                            <td>{{ convertTime2(item.booking_pickup_time) }}</td>
                            <td>
                                {{ convertDate(item.appoinment_datetime) }}
                                {{ convertTime(item.appoinment_datetime) }}
                            </td>
                            <td>
                                {{ convertDate(item.garage_time) }}
                                {{ convertTime(item.garage_time) }}
                            </td>

                            <td>{{ item.driver_name == null ? "" : item.driver_name }} {{
                                item.driver_lastname == null ? "" : item.driver_lastname }}</td>
                            <td>{{ getDriverVehicle(item.driver_vehicle_type) }}</td>
                            <td>{{ item.driver_phone_number == null ? "" : item.driver_phone_number }}</td>

                            <td>{{ getAddress(item.from).from }}</td>
                            <td>{{ getAddress(item.to).to }}</td>

                            <!-- Start Actions  -->
                            <td>
                                <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL"
                                    transition="scale-transition" :offset-y="true">
                                    <template #button-content>
                                        <feather-icon icon="MoreVerticalIcon" size="16"
                                            class="align-middle text-body" />
                                    </template>
                                    <template v-slot:activator="{ on, attrs }">
                                        <b-btn color="primary" v-bind="attrs" v-on="on" icon ripple>
                                        </b-btn>
                                    </template>
                                    <b-list-group style="padding: 0px; margin-bottom: 0px" dense rounded>
                                        <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                                            <b-list-group-item class="font-weight-bold"
                                                style="border: none; padding: 5px; color: #7367f0"
                                                @click="openUpdateBooking(item.booking_id)">
                                                <feather-icon icon="CheckIcon" />
                                                Edit
                                            </b-list-group-item>
                                        </b-list-group-item>
                                    </b-list-group>
                                    <b-list-group style="padding: 2px; margin-bottom: 2px" dense rounded>
                                        <router-link class="urlPagina"
                                            :to="{ name: 'details-booking', query: { id: item.booking_id } }">
                                            <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                                                <b-list-group-item class="font-weight-bold"
                                                    style="border: none; padding: 5px">
                                                    <feather-icon icon="FileTextIcon" />
                                                    Details
                                                </b-list-group-item>
                                            </b-list-group-item>
                                        </router-link>
                                    </b-list-group>
                                    <!-- is_charge_hold 3 = is expirated -->
                                    <b-list-group
                                        v-if="(item.charge_id === null && (item.status == 4) == false) || item.is_charge_hold == 3"
                                        style="padding: 0px; margin-bottom: 0px" dense rounded>
                                        <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                                            <b-list-group-item class="font-weight-bold"
                                                style="border: none; padding: 5px; color: #7367f0"
                                                @click="showPaymentMethods(item)">
                                                <feather-icon icon="CheckIcon" />
                                                Pre-authorize payment
                                            </b-list-group-item>
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-dropdown>
                            </td>
                            <!-- End Actions  -->
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <th>Actions</th>
                            <th aria-sort="none" @click="getItemsSort('booking_id')"><i
                                    class="fa fa-fw fa-sort"></i>Confirmation number
                            </th>
                            <th @click="getItemsSort('garage_time')">Preauthorization reminder</th>
                            <th @click="getItemsSort('selfpay_name')">Customer</th>
                            <th @click="getItemsSort('selfpay_phone_number')">Customer Phone</th>
                            <th @click="getItemsSort('statuscodes_status')">Status</th>
                            <th @click="getItemsSort('service_type')">Service Type</th>
                            <th @click="getItemsSort('payment_type')">Booking Category</th>
                            <th @click="getItemsSort('company_legal_name')">Company</th>
                            <th @click="getItemsSort('pickup_time')">PickUp time</th>
                            <th @click="getItemsSort('appoinment_datetime')">Appointment Datetime</th>
                            <th aria-sort="none" class="headerth" @click="getItemsSort('garage_time')"> Central zone
                                time</th>
                            <th @click="getItemsSort('driver_name')">Driver</th>
                            <th @click="getItemsSort('driver_vehicle_type')">Vehicle Type</th>
                            <th @click="getItemsSort('driver_phone_number')">Driver phone</th>
                            <th @click="getItemsSort('from')" style="min-width: 100px">Pickup Address</th>
                            <th @click="getItemsSort('to')" style="min-width: 100px">Drop Off Address</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td :class="{ show_space: isShowSpace }" colspan="17" variant="secondary" class="text-left">
                                Total Rows: <b>{{ totalItems }}</b>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>




            <div class="pagination_block" v-if="listItems.length > 0">
                <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-center">
                        <li class="page-item" :class="{ disabled: pagination.prev_page_url == null }">
                            <button type="button" class="page-link" @click="getItems(pagination.first_page_url)">
                                &laquo;
                            </button>
                        </li>
                        <li class="page-item" :class="{ disabled: pagination.prev_page_url == null }">
                            <button type="button" class="page-link" @click="getItems(pagination.prev_page_url)">
                                Previous
                            </button>
                        </li>
                        <li class="page-item" v-if="current_page - 2 > 0">
                            <button type="button" class="page-link" @click="
                                getItems(pagination.path + '?page=' + (current_page - 2))
                                ">
                                {{ current_page - 2 }}
                            </button>
                        </li>
                        <li class="page-item" v-if="pagination.prev_page_url">
                            <button type="button" class="page-link" @click="getItems(pagination.prev_page_url)">
                                {{ current_page - 1 }}
                            </button>
                        </li>
                        <li class="page-item">
                            <button type="button" class="page-link"
                                :class="{ current: pagination.current_page == current_page }">
                                {{ current_page }}
                            </button>
                        </li>
                        <li class="page-item" v-if="pagination.next_page_url">
                            <button type="button" class="page-link" @click="getItems(pagination.next_page_url)">
                                {{ current_page + 1 }}
                            </button>
                        </li>
                        <li class="page-item" v-if="current_page + 2 <= last_page">
                            <button type="button" class="page-link" @click="
                                getItems(pagination.path + '?page=' + (current_page + 2))
                                ">
                                {{ current_page + 2 }}
                            </button>
                        </li>
                        <li class="page-item" :class="{ disabled: pagination.next_page_url == null }">
                            <button type="button" class="page-link" @click="getItems(pagination.next_page_url)">
                                Next
                            </button>
                        </li>
                        <li class="page-item" :class="{ disabled: pagination.next_page_url == null }">
                            <button type="button" class="page-link" @click="getItems(pagination.last_page_url)">
                                &raquo;
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>

            <div v-if="listItems.length == 0">
                -- Total rows 0 --
            </div>
        </div>

        <b-modal id="modal-select-payments-method" centered hide-footer size="lg">

            <b-row style="display: flex;flex-direction: column;margin:20px;align-items: flex-start;">
                <h2>Booking - ticket {{ seletedIdBooking }}</h2>
                <span class="mt-2" style="background: #ee8b52;color: white;padding: 10px;"
                    v-b-modal.modal-edit-payments-method>Add or edit
                    payments
                    methods</span>

                <h4 class="mt-1">{{ statusPaymentMethods }}</h4>

                <template v-if="listPaymentMethods.length > 0">
                    <h4 class="mt-0">Select the payment method</h4>
                    <div style="width: 100%;">
                        <BookingPaymentsMethod :key="keyBookingSelectPaymentsMethod"
                            :idCustomerPaymentMehtod="selectedCustomerId" :isSelfpay="this.selectedIsSelfpay"
                            :proPaymentsMethods="listPaymentMethods"
                            v-on:setSeletedPaymentMethod="setSeletedPaymentMethod($event)"></BookingPaymentsMethod>
                    </div>
                </template>


            </b-row>
            <b-button v-if="listPaymentMethods.length > 0" class="btn btn-primary float-right mr-2"
                @click="preauthorizePayment(seletedIdBooking, seletectedSelfpayId, selectedPaymentMethodId)"
                style="background: #7367f0 !important;">
                Pre-authorize payment
            </b-button>
        </b-modal>


        <b-modal id="modal-edit-payments-method" centered hide-footer size="xl">
            <b-row style="display: flex;flex-direction: column;margin:20px">
                <PaymentMethodSelfPaid v-on:updatePaymentMethodList="updatePaymentMethodList($event)"
                    :key="keyPageEditPaymentSelfpay" :proclientId="selectedClientId"
                    :proPaymentMethods="listPaymentMethods" v-if="this.selectedIsSelfpay == true">
                </PaymentMethodSelfPaid>

                <CaListPaymentMethod v-if="this.selectedIsSelfpay == false"
                    v-on:updatePaymentMethodList="updatePaymentMethodList($event)"
                    :proListPaymentMethods="listPaymentMethods" :proPaymentType="seletedPaymentType"
                    :proStripeCustomerId="seletedCompanyStripeCustomerId" :proCaid="selectedCustomerId"
                    :key="keyPageEditPaymentCa" :proShowEditPaymentMethod="false">
                </CaListPaymentMethod>

            </b-row>

        </b-modal>



    </div>
</template>

<script>
import $ from 'jquery'
import moment from "moment";
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BListGroup,
    BListGroupItem,
    BFormSelect,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BTh,
    BTbody,
    BTfoot,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { getMessageError } from "@core/utils/utils";
import { convertToMMDDYYYY, convertToHHMM, convertToHHMM2 } from "@core/utils/dates";
import UserListAddNew from "@core/components/infoClients/UserListAddNew";
import { selectedRow } from "@core/utils/cssStyle";
import BookingPaymentsMethod from '@core/components/bookings/BookingPaymentsMehod'
import PaymentMethodSelfPaid from '@/views/Pages/admin/PaymentMethodSelfPaid'
import CaListPaymentMethod from '@/@core/components/CA/CaListPaymentMethod.vue'


export default {
    name: "ListReservationToCancel",
    components: {
        UserListAddNew,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BFormTextarea,
        BFormCheckbox,
        BFormCheckboxGroup,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BListGroup,
        BListGroupItem,
        BFormSelect,
        BTableSimple,
        BThead,
        BTr,
        BTd,
        BTh,
        BTbody,
        BTfoot,
        BFormGroup,
        flatPickr,
        vSelect,
        BFormInput,
        moment,
        BookingPaymentsMethod,
        PaymentMethodSelfPaid,
        CaListPaymentMethod
    },
    data() {
        return {
            BFormInput: "",
            messageSendTo: [],
            isDefaultMessage: null,
            textDefaultMessage: "",
            isNoteText: true,
            noteText: "",
            isMessageText: true,
            messageText: "",
            currentId: "",
            status: "",
            currentAlternativeNumberDriver: "",
            currentAlternativeNumberPassenger: "",
            totalSeconds: [],
            timerInterval: [],
            hour: 0,
            sec: 0,
            min: 0,
            dispHour: 0,
            dispMin: 0,
            dispSec: 0,
            listItems: [],
            perPage: 10,
            currentPage: 1,
            totalItems: 0,
            pageOptions: [3, 5, 10],
            pagination: {},
            current_page: 1,
            last_page: 0,
            fields: [
                { label: "Customer", key: "name_selfpay", variant: "info" },
                { label: "Status", key: "status_code", tdClass: "myclass" },
                "pickup_time",
                "surgery_type",
                "appoinment_datetime",
                "city",
                "actions",
            ],
            fields2: ['name', 'lastname', 'email', 'vehicle', 'workCities', 'actions'],
            filterNameDriver: null,
            perPageTableDriver: 15,
            currentPageTableDriver: 1,
            selectedStatus: null,
            options: [
                { value: "4", title: "Cancelled" },
                { value: "1", title: "Cancellation pending" },
                { value: "3", title: "Completed" },
                { value: "12", title: "Update needed" },
                { value: "7", title: "Enroute" },
                { value: "2", title: "One way" },
                { value: "5", title: "On board" },
                { value: "6", title: "At facility" },
                { value: "0", title: "Trip pending" },
                { value: "9", title: "Driver confirmed" },
                { value: "10", title: "Driver scheduled" },
                { value: "11", title: "Not showed" },
            ],
            rangeDate: null,
            searchingText: "",
            ticketToUpdateNumber: 0,
            ticketToUpdateStatus: "",
            listDrivers: [],
            listDrivers2: [],
            selectedDriverId: null,
            selectDriverName: null,
            columnOrder: 'garage_time',
            typeOrder: null,
            orderDesc: true,
            isOrdering: false,
            documentHtml: "",
            isShowSpace: false,
            driversData: [],
            selectedPaymentMethodId: null,
            selectedClientId: null,
            selectedCustomerId: null,
            listPaymentMethods: [],
            keyBookingSelectPaymentsMethod: 0,
            keyPageEditPaymentCa: 0,
            keyPageEditPaymentSelfpay: 0,
            seletedIdBooking: null,
            statusPaymentMethods: null,
            selectedIsSelfpay: false,
            seletedPaymentType: '',
            seletedCompanyStripeCustomerId: '',
            seletectedSelfpayId:0
        };
    },
    methods: {
        masterSearch(e) {
            if (e.keyCode == 13) {
                this.getClientesByFilter("");
            }
        },
        getBookings() {
            this.$http
                .post(`admin/panel/mastersearch/bookingList/` + this.searchingText)
                .then((response) => {
                    this.listItems = response.data.data.data;
                    this.totalItems = response.data.data.total;
                    this.current_page = response.data.data.current_page;
                    this.last_page = response.data.data.last_page;
                    this.pagination = response.data.data;
                })
                .catch((res) => {
                    console.log(res.data);
                });
        },
        convertDate(data) {
            return convertToMMDDYYYY(data);
        },
        convertTime(data) {
            return convertToHHMM(data);
        },
        convertTime2(data) {
            return convertToHHMM2(data);
        },
        getItems(url) {
            this.getClientesByFilter(url);
        },
        getClientes(url) {
            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            if (url.length === 0) {
                url = "admin/panel/booking/all_list";
            }

            this.$http
                .get(url)
                .then((response) => {
                    this.listItems = response.data.data.data;
                    this.totalItems = response.data.data.total;
                    this.current_page = response.data.data.current_page;
                    this.last_page = response.data.data.last_page;
                    this.pagination = response.data.data;
                    this.$swal.close();
                })
                .catch((res) => {
                    let message = getMessageError(res);
                    this.$swal({
                        title: message,
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        },
        loadPassengerWaitingTimeForDriver() {
            this.listItems.forEach((element) => {
                let dateWaiting = moment(
                    element.passenger_waiting_time_for_driver_datetime
                );
                if (dateWaiting.isValid()) {
                    this.clearIntervalTimes(element);
                    this.startTimer(element, false);
                }
            });
        },
        async getClientesByFilter(url) {
            this.$swal({
                title: "Please, wait loading data...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });

            let data = this.getValuesParameters();

            if (url.length === 0) {
                url = `admin/panel/booking/get_booking_byFilter`;
            }

            await this.$http
                .post(url, data)
                .then((response) => {
                    this.listItems = response.data.data.data;
                    this.totalItems = response.data.data.total;
                    this.current_page = response.data.data.current_page;
                    this.last_page = response.data.data.last_page;
                    this.pagination = response.data.data;
                    this.$swal.close();
                    this.loadPassengerWaitingTimeForDriver();
                })
                .catch((res) => {
                    this.$swal({
                        title: getMessageError(res),
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        },
        getValuesParameters() {
            let data = {};
            let dateStart = "";
            let dateEnd = "";

            //Status variable filter
            let status = {};
            if (this.selectedStatus !== null) {
                status = this.selectedStatus.map((item) => {
                    let value = item.value;
                    return value;
                });
            }

            //Dates variables filter
            if (this.rangeDate !== null) {
                dateStart = this.rangeDate.split("to")[0];
                dateEnd = this.rangeDate.split("to")[1];
            }

            data = {
                status: status,
                dateStart: dateStart,
                dateEnd: dateEnd,
                text: this.searchingText,
                columnOrder: this.columnOrder,
                orderDesc: this.orderDesc,
                payments_whithout_preauthorize: 1,
            };

            return data;
        },
        getDriverName(item) {
            let res = "";
            if (item.driver) {
                res = `${item.driver.name} ${item.driver.lastname}`;
            }
            return res;
        },
        getDriverId(item) {
            let res = "";
            if (item.driver) {
                res = item.driver.driver_id;
            } else {
                res = null;
            }
            return res;
        },
        getAddress(item) {
            let res = "";

            if (item) {
                res = JSON.parse(item);
            }
            return res;
        },
        getDriverPhone(item) {
            let res = "";
            if (item.driver) {
                res = item.driver.phone_number;
                res = res == "null" ? "" : res;
            }
            return res;
        },
        getDriverVehicle(item) {
            console.log('getDriverVehicle')
            let res = "";
            try {
                res = item == null || item == "null" ? "" : item.toUpperCase();
            } catch (error) {
                console.log(error);
            }
            return res;
        },
        getSelfPayName(item) {
            let res = "";
            if (item.self_pay) {
                res = item.self_pay.name + " " + item.self_pay.lastname;
            }
            return res;
        },
        getSelfPhone(item) {
            let res = "";
            if (item.self_pay) {
                res = item.self_pay.phone_number;
                res = res == "null" ? "" : res;
            }
            return res;
        },
        getTypeClient(item) {
            let res = "";
            try {
                if (item.payment_type == null) {
                    res = "Selfpay"
                }
                if (item.payment_type == 'il') {
                    res = "Contract Account"
                }
                if (item.payment_type == 'cc') {
                    res = "Corporate Account"
                }

                return res;

            } catch (error) {

            }

            return res;
        },
        getServiceType(item) {
            try {
                if (item == 'sedan') {
                    return 'Sedan';
                }
                if (item == 'wheelchairvan') {
                    return 'Wheelchair Van';
                }
                if (item == 'gurneygan') {
                    return 'Ambulance';
                }
            } catch (error) {

            }

            return "";
        },
        getTicketData(item) {
            if (item.booking_id === this.ticketToUpdateNumber) {
                this.ticketToUpdateNumber = null;
                this.ticketToUpdateStatus = null;
                this.selectDriverName = null;
                this.selectedDriverId = null;
                return false;
            }

            this.ticketToUpdateNumber = item.booking_id;
            this.ticketToUpdateStatus = this.options.filter(
                (x) => x.value == item.status
            );
            let driverName = this.getDriverName(item);
            let driver_id = this.getDriverId(item);

            this.selectDriverName = driverName;
            this.selectedDriverId = driver_id;
        },
        openUpdateBooking(id) {
            this.$router.push({
                name: "details-reservation",
                params: { id: id },
            });
        },
        async showPaymentMethods(item) {
            this.seletedIdBooking = item.booking_id;

            this.selectedClientId = item.selfpay_client_id;
            this.seletectedSelfpayId=item.selfpay_id;
          
            if (this.getTypeClient(item) == "Selfpay") {
                this.selectedIsSelfpay = true;
                this.selectedCustomerId = item.selfpay_id;
            } else {
                this.selectedIsSelfpay = false;
                this.selectedCustomerId = item.selfpay_ca_id;
            }
            this.seletedPaymentType = item.payment_type;
            this.seletedCompanyStripeCustomerId = item.c_s_customer_id;

            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
            });
            this.statusPaymentMethods = "Loading payments methods";
            console.log("this.selectedCustomerId", this.selectedCustomerId)
            console.log("xxxxxxxxxx",this.selectedIsSelfpay);
            await this.getListPaymentMethods(this.selectedIsSelfpay, this.selectedCustomerId);

            if (this.listPaymentMethods.length > 0) {
                this.statusPaymentMethods = "";
            }
            if (this.listPaymentMethods.length === 0) {
                this.statusPaymentMethods = "There are not payment methods";
            }
            this.$swal.close();
            this.$bvModal.show("modal-select-payments-method");

        },
        getItemsSort(column) {
            if (this.isOrdering == false) {
                this.orderDesc = this.orderDesc == null ? true : !this.orderDesc;
                this.columnOrder = column;
                this.getClientesByFilter("");
            }
        },
        selectedRow() {
            var index,
                table = document.getElementById("table");
            if (table == null || table == undefined) {
                return false;
            }

            for (var i = 1; i < table.rows.length; i++) {
                table.rows[i].classList.remove("selected");
                table.rows[i].onclick = function () {
                    // remove the background from the previous selected row
                    if (typeof index !== "undefined") {
                        table.rows[index].classList.toggle("selected");
                    }
                    // get the selected row index
                    index = this.rowIndex;
                    // add class selected to the row
                    this.classList.toggle("selected");
                };
            }
        },
        async loadData() {
            await this.getClientesByFilter("");
        },
        getReminder(currentDate, data) {
            let res = "";

            try {
                let date1 = moment(new Date());
                let date2 = moment(currentDate);
                let diff = date2.diff(date1, 'days');

                if (diff <= 0) {
                    res = "This should be pre-authorized"
                }
                if (diff > 0) {
                    res = `${diff} Days left for appoiment date`
                }
                //is_charge_hold 3 = charge expired
                if ((data.charge_id != null || data.is_pre_authorize_payment == 1) && data.is_charge_hold != 3) {
                    res = "It's already pre-authorizated"
                }


                if ((data.status == 4 || item.status == 11) == true) {
                    res = "";
                }
            } catch (error) {

            }

            return res;
        },
        ShouldPreauthirze(currentDate, data) {
            let res = false;

            try {
                //is_charge_hold 3 = charge expired
                if ((data.charge_id != null || data.is_pre_authorize_payment == 1) && data.is_charge_hold != 3) {
                    return false;
                }
                let date1 = moment(new Date());
                let date2 = moment(currentDate);
                let diff = date2.diff(date1, 'days');

                if (diff <= 0) {
                    res = true
                }
            } catch (error) {

            }
            return res;
        },
        getCompanyName(item) {
            let res = "";
            try {
                if (item.payment_type == null) {
                    res = "Selfpay";
                } else {
                    res = item.company_legal_name;
                }

                return res;
            } catch (error) {

            }

            return res;
        },
        async getListPaymentMethods(isSelfpay, id, updateEditAddPaymentComponent = false) {
            this.selectedPaymentMethodId = null;

            this.listPaymentMethods = [];
            this.keyBookingSelectPaymentsMethod = this.keyBookingSelectPaymentsMethod + 1;
            this.keyPageEditPaymentSelfpay = this.keyPageEditPaymentSelfpay + 1;
            if (updateEditAddPaymentComponent == true) {
                this.keyPageEditPaymentCa = this.keyPageEditPaymentCa + 1;
            }

            let url = "";
            if (isSelfpay == true) {
                url = `/admin/panel/get_selfpay_payment_methods/${id}`;
            } else {
                url = `/admin/panel/get_ca_payment_methods/${id}`;
            }

            await this.$http
                .get(url)
                .then((response) => {
                    this.listPaymentMethods = response.data.data;
                    console.log(this.listPaymentMethods)

                    let defaultPaymentMethod = this.listPaymentMethods.find(x => x.is_default == 0);
                    if (defaultPaymentMethod) {
                        this.selectedPaymentMethodId = defaultPaymentMethod.stripe_payment_method_id;
                    }

                    this.keyBookingSelectPaymentsMethod = this.keyBookingSelectPaymentsMethod + 1;
                    this.keyPageEditPaymentSelfpay = this.keyPageEditPaymentSelfpay + 1;
                    if (updateEditAddPaymentComponent == true) {
                        this.keyPageEditPaymentCa = this.keyPageEditPaymentCa + 1;
                    }
                })
                .catch((res) => {
                    let message = getMessageError(res);
                    this.$swal({
                        title: message,
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                });
        },
        setSeletedPaymentMethod(paymentMethodId) {
            this.selectedPaymentMethodId = paymentMethodId;
        },
        preauthorizePayment(idBooking, idSelfpay, paymentMethodId) {
            this.$swal({
                title: "Do you want pre-authorize this booking?",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                buttonsStyling: true,
            }).then((result) => {
                if (result.value === true) {
                    this.$swal({
                        title: "Please, wait...",
                        didOpen: () => {
                            this.$swal.showLoading();
                        },
                    });


                    let formData = new FormData();
                    formData.append("idBooking", idBooking);
                    formData.append("idSelfpay", idSelfpay);
                    formData.append("selectedPaymentMehtod", paymentMethodId)

                    this.$http
                        .post(`/admin/panel/preauthorize_payment`, formData)
                        .then((response) => {
                            this.listItems = this.listItems.filter(x => x.booking_id !== idBooking);
                            this.$swal.close();
                            this.$bvModal.hide("modal-select-payments-method");
                        })
                        .catch((error) => {
                            let message = getMessageError(error);
                            this.$swal({
                                title: message,
                                icon: "error",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        });
                }
            });
        },
        async updatePaymentMethodList(updateEditAddPaymentComponent = false) {

            this.statusPaymentMethods = "Loading payments methods";
            await this.getListPaymentMethods(this.selectedIsSelfpay, this.selectedCustomerId);
            if (this.listPaymentMethods.length > 0) {
                this.statusPaymentMethods = "";
            }
            if (this.listPaymentMethods.length === 0) {
                this.statusPaymentMethods = "There are not payment methods";
            }
        },
    },
    mounted() {
        this.loadData();
    },
    updated() {
        selectedRow();
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}

.urlPagina {
    text-decoration: none;
    color: #7367f0;
}

.urlPagina:hover {
    background: linear-gradient(118deg,
            #7367f0,
            rgba(115, 103, 240, 0.7)) !important;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
}

.list-group-item:hover {
    background: linear-gradient(118deg,
            #7367f0,
            rgba(115, 103, 240, 0.7)) !important;
    color: #fff !important;
    cursor: pointer;
}

.urlPagina::before {
    background-color: currentColor !important;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.box {
    box-shadow: 0px 14px 20px 0px rgba(143, 143, 143, 0.2) !important;
}

.my-class {
    background: #7367f0;
}

.green_timer {
    background: green;
    color: white;
    padding: 3px;
    border-radius: 8px;
}

.current {
    background: #7367f0;
    color: white;
}

.table-responsive {
    overflow-x: clip !important;
}

.headerth {
    cursor: pointer;
}

.highlighted {
    background: red;
}

tr {
    cursor: pointer;
    padding: 0;
}

th {
    background: #4b4b4b !important;
    color: white !important;
    text-transform: uppercase;
}

td {
    padding: 0;
}

.table td,
.table th {
    padding: 3px;
    font-size: x-small;
    min-width: 40px;
    border: solid 1px;
    border-color: #303033;
    background: #fdfdfd;
    color: #303033;
}

.show_space {
    padding-bottom: 250px;
}

.textDanger {
    color: red;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>